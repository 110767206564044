/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: red;
    margin-left: 10px;
}

/* Tooltip text */
.tooltip .tooltip-text {
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the icon */
    left: 50%;
    margin-left: -110px; /* Use margin to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when hovering over the tooltip container */
.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
