.result-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
    font-family: Arial, sans-serif;
}

/* Cards on the left column */
.result-card {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Right column content */
.right-column-content {
    padding-left: 20px;
}

/* Chronotype description box */
.chronotype-description {
    background-color: #e2e4e6;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.chronotype-description p {
    font-size: 16px;
    line-height: 1.5;
    color: #1d455c;
}

/* Headings */
h2, .result-container h4, .result-container h5, .result-container h6 {
    color: #1f6da2;
}

h3 {
    color: #256390;
}
