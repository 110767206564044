/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

/* Parent Container for Footer */
.footer-container {
    background-color: #f0f8ff;
    padding: 80px 0; /* Padding to create space around the footer */
}

/* Footer Container */
.footer-section {
    background: #648cd3; 
    padding: 20px 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px; /* Adjust this value to make the content closer */
    margin-bottom: 20px;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-logo img {
    width: 180px;
    margin-bottom: 10px;
    margin-left: -50px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    width: 60%; /* Adjust this value to make the content closer */
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-column h3 {
    font-family: 'Josefin Sans', sans-serif;
    margin-bottom: 10px;
}

.footer-column a {
    font-family: 'Josefin Sans', sans-serif;
    color: white;
    text-decoration: none;
    margin-bottom: 15px; /* Increase the margin between links */
}


.footer-column a:hover {
    text-decoration: underline;
}

.footer-bottom {
    width: 100%;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 0.9em;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 10px;
}
