.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure it takes the full height of the viewport */
}

.dashboard-content {
  display: flex;
  flex: 1;
  margin-top: 64px; /* Adjust this to the height of your AppBar */
}

.menu {
  width: 250px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto; /* Allow scrolling if content overflows */
}

header {
  display: flex;
  justify-content: flex-end;
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 20px;
}

.username {
  font-weight: bold;
}

/* Ensure the calendar itself takes full available space */
.calendar-container {
  width: 100%; /* Adjust width to take more space */
  max-width: 1200px; /* Optional: set a max width */
  margin: 0 auto; /* Center align the calendar */
}

.calendar-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%; /* Make navigation buttons take full width */
}

.calendar-button {
  background-color: #1d455c;  /* Dark blue */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.calendar-button:hover {
  background-color: #1f6da2;  /* Lighter blue */
}
