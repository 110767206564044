
body {
    background-color: #f4f4f4; /* Light background for the entire dashboard */
    color: #333; /* Standard text color */
    font-family: 'Arial', sans-serif; /* Choose a modern, readable font */
}

/* Container for the Overview page */
.overview-container {
    padding: 20px;
}

/* Style for the cards */
.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Light border for definition */
}

/* Title styles */
.overview-title {
    color: #007BFF; /* Use a primary color for headings */
    font-weight: bold;
    margin-bottom: 15px;
}



/* Button styling */
button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

/* Customize the chart container */
.chart-container {
    background-color: #f9f9f9;

    border-radius: 8px;
    margin-top: 20px;
}
