.observation-card {
    border: 1px solid #e0e0e0; /* Subtle border */
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.observation-card:hover {
    background-color: #f9fbfd; /* Slightly different background on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
}

.observation-card .MuiCardContent-root {
    padding: 16px; /* Increase padding within the card content */
}

.observation-card .MuiTypography-body2 {
    font-size: 1rem;
    color: #333; /* Darker text color for readability */
    font-weight: 500; /* Slightly bolder font for emphasis */
}
