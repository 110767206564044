.container {
    background-color: #e2e4e6;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
    color: #1d455c;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #256390;
}

.input, .slider {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.save-button {
    background-color: #1f6da2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #256390;
}

.response-group {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.response-group:nth-child(odd) {
    background-color: #f9f9f9;
}

.response-group:nth-child(even) {
    background-color: #eef2f3;
}

.response-date {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.response-item {
    padding: 5px 0;
    border-top: 1px solid #ccc;
}

.response-item:first-child {
    border-top: none;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-controls span {
    font-weight: bold;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s, border-bottom-color 0.3s;
}

.tab-button:hover {
    background-color: #e1e1e1;
}

.active-tab {
    background-color: #007bff !important;
    color: #fff !important;
    border-bottom-color: #007bff !important;
}

.tab-button:not(.active-tab):hover {
    background-color: #e1e1e1;
}
