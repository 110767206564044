/* Ensure default color is black */
.react-calendar__tile {
    color: black !important;
}

/* Red for days with fewer than 10 entries in the observation period */
.red-day {
    background-color: #ffcccc !important;
    color: red !important;
    border-radius: 50%;
    height: 40px;
    width: 15px
}

/* Green for days with 10 or more entries in the observation period */
.green-day {
    background-color: #ccffcc !important;
    color: green !important;
    border-radius: 50%;
}

.blue-day {
    background-color: #cce5ff !important;
    color: blue !important;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.react-calendar__navigation button {
    color: black !important;
    font-weight: bold; /* Optional: Make it bold for better readability */
}

/* Remove any hover effects that may be affecting visibility */
.react-calendar__navigation button:hover {
    color: black !important;
    background-color: transparent !important;
}