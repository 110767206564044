.note-manager {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.note-manager h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #1d455c;
}

.note-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.note-input textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    resize: none;
    font-size: 1em;
}

.note-input .btn {
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1em;
}

.add-btn {
    background-color: #007bff;
    color: white;
}

.update-btn {
    background-color: #28a745;
    color: white;
}

.note-list {
    margin-top: 20px;
}

.note-item {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.note-item p {
    margin: 0;
    color: #333;
}

.note-item small {
    display: block;
    margin-top: 5px;
    color: #888;
}

.note-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.note-actions .btn {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 0.9em;
}

.edit-btn {
    background-color: #ffc107;
    color: white;
}

.delete-btn {
    background-color: #dc3545;
    color: white;
}
