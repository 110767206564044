@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.login-card {
    background-color: #EFF8FF !important;
    width: 60vh !important;
    position: relative !important;
    left: 35% !important;
    top: 15% !important;
    border-radius: 30px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;
    margin-bottom: 70px !important;
    font-family: 'Josefin Sans', sans-serif !important;
}

.login-card.login {
    height: 460px;
    margin-top: 100px !important;
}

.login-card.register {
    margin-top: 100px !important;
}
.login-form.login .login-input {
    margin-bottom: 20px !important; /* Added more space between input fields */

}

.login-form.login .login-button {
    margin-top: 30px !important; /* Increased the margin-top to create more space between inputs and button */
    background-color: #1f6da2 !important;
    color: #fff !important;
    padding: 15px !important; /* Increased padding for button */
    border-radius: 4px !important;
    width: 100% !important;
    font-family: 'Josefin Sans', sans-serif !important;
}

.login-tabs {
    width: 100% !important;
    margin-bottom: 20px !important; 
}

.login-tab {
    color: #3c577B !important;
    font-family: 'Josefin Sans', sans-serif !important; 
}

.login-form {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.login-input {
    background-color: #e8ecf1 !important;
    font-family: 'Josefin Sans', sans-serif !important;
    margin-bottom: 20px !important; /* Added more space between input fields */
}

.login-button {
    margin-top: 30px !important; /* Increased the margin-top to create more space between inputs and button */
    background-color: #1f6da2 !important;
    color: #fff !important;
    padding: 10px !important;
    border-radius: 4px !important;
    width: 100% !important;
    font-family: 'Josefin Sans', sans-serif !important;
}

.login-button:hover {
    background-color: #1f6da2 !important;
}

.login-button:focus {
    background-color: #1f6da2 !important;
}

.login-button:active {
    background-color: #f4c76f !important;
}
