/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content box */
.modal-content {
    background: #fff; /* White background for modal */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    max-width: 500px;
    width: 90%;
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
}

/* Modal title */
.modal-content h4 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

/* Form labels */
.modal-content label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

/* Input fields */
.modal-content input[type="time"],
.modal-content input[type="range"] {
    width: 100%;
    padding: 2px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Range value display */
.modal-content span {
    display: block;
    margin-top: -10px;
    margin-bottom: 15px;
    text-align: right;
    font-size: 14px;
    color: #666;
}

/* Buttons */
.modal-content button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content button:hover {
    background-color: #f0f0f0;
}

.modal-content button:first-of-type {
    background: #4caf50; /* Green save button */
    color: white;
}

.modal-content button:first-of-type:hover {
    background: #45a049;
}

.modal-content button:last-of-type {
    background: #f44336; /* Red cancel button */
    color: white;
}

.modal-content button:last-of-type:hover {
    background: #e53935;
}

/* Modal animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
