@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700&display=swap');

/* Container for the About Me section */
.about-me-container {
   background-color:  #f0f8ff;   
   padding: 50px 0; /* Add padding to the container */
}

.about-me-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #648cd3;
    opacity: 80%;
    border-radius: 20px;
    color: white;
    width: 70%;
    margin: 0 auto; /* Center the section horizontally */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.about-me-title h2 {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 10px;
}

/* Content layout */
.about-me-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Description and avatar styling */
.about-me-description {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.3em;
    line-height: 1.4;
    margin-top: 20px;
    max-width: 80%;
}

.about-me-avatar {
    display: flex;
    align-items: center;
}

.about-me-avatar img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
}

.about-me-avatar h3 {
    margin: 0;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5em;
}

.about-me-avatar p {
    margin: 0;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
}

/* Introduction styling */
.about-me-introduction {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5em;
    margin-top: 20px;
}
