@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.navbar {
    z-index: 1200 !important;
    background-color: #ffffff !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
    color: #3c577B !important;
}

.navbar-link {
    margin: 0 15px !important;
    text-decoration: none !important;
    color: #3c577B !important;
    font-family: 'Josefin Sans', sans-serif !important;
    font-size: 1em !important;
}

.navbar-link:hover {
    color: #007BFF !important;
}

.navbar-img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    margin-right: 10px !important;
}

.menu {
    width: 250px !important;
    background-color: #ffffff !important;
}

.drawer-text {
    font-family: 'Josefin Sans', sans-serif !important;
    color: #3c577B !important;
}

.MenuItem {
    color: blue;
  }