.forgot-password-card {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .forgot-password-input {
    margin-bottom: 20px;
  }
  
  .forgot-password-button {
    width: 100%;
  }
  