/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.services-section {
    padding: 50px 20px;
    background-color: #f0f8ff;
    text-align: center;
}

.services-title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #3c577B;
}

.services-subtitle {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
    color: #93c1f9;
    margin-bottom: 40px;
    width: 70%;
    margin: 0 auto;
    width: 60%;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s;
    height: 290px;
}

.service-card:hover {
    transform: translateY(-10px);
}

.service-icon img {
    width: 80px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height as needed */
    object-fit: contain; /* Ensures the image fits within the given dimensions */
    margin-bottom: 10px;
    margin-top: 10px;
}

.service-title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5em;
    color: #3c577B;
    margin-bottom: 10px;
}

.service-description {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1em;
    color: #6c757d;
    width: 80%;
    margin: 0 auto; /* Center the description */
    margin-top: 30px;
}

.learn-more-button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
}

.learn-more-button:hover {
    background-color: #0056b3;
}
