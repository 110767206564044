/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #f0f8ff;
    text-align: center; /* Center the text within the section */
}

.hero-content, .hero-image {
    flex: 1;
    max-width: 50%;
    box-sizing: border-box;
}

.hero-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #3c577B;
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #93c1f9;
    width: 70%;
}

.register-button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.register-button:hover {
    background-color: #0056b3;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
}
