.personal-info {
    font-family: Arial, sans-serif;
    color: #1d455c; 
    background-color: #e2e4e6; 
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 20px auto;
}

.personal-info-form {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin-bottom: 10px;
    color: #256390; 
}

.form-input {
    padding: 8px;
    border: 1px solid #1f6da2; 
    border-radius: 4px;
    margin-top: 5px;
}

.submit-button, .edit-button {
    background-color: #1d455c; 
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.submit-button:hover, .edit-button:hover {
    background-color: #256390;
}

.personal-info-display {
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.info-text {
    margin: 5px 0;
}
