.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
}

.legend-card {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 100%;
    max-width: 400px;
}

.legend-card h3 {
    margin-top: 0;
    font-size: 18px;
}

.legend-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.legend-card li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.legend-card .legend-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 4px;
}
