/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.chronotype-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    background-color: #f0f8ff;
    text-align: center;
}


.chronotype-content {
    max-width: 50%;
    text-align: left;
}

.chronotype-content h2 {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5em;
    color: #3c577B;
    margin-bottom: 20px;
}

.chronotype-content p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
    color: #6c757d;
    margin-bottom: 40px;
    width: 80%;
}

.chronotype-section-2 .chronotype-content {
    margin-left: 150px; /* Adjust this value as needed */
}

.learn-more-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    font-family: 'Josefin Sans', sans-serif;
}

.learn-more-btn:hover {
    background-color: #0056b3;
}

.chronotype-image img {
    max-width: 60%;
    height: auto;
    margin-left: 25px;
    border-radius: 10px;
}

.chronotype-image-2 img {
    max-width:100%;
    height: auto;
    margin-left: 25px;
    border-radius: 10px;
}
.chronotype-image-3 img {
    max-width:100%;
    height: auto;
    margin-left: 25px;
    border-radius: 10px;
}


