.performance-question {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 0 auto;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    border: none;
    background-color: #e2e2e2;
    cursor: pointer;
    border-radius: 4px;
}

.tabs button.active {
    background-color: #1f6da2;
    color: white;
}

.tab-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.slider {
    width: 100%;
    margin: 10px 0;
}

.save-button {
    background-color: #1f6da2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.response-history {
    list-style: none;
    padding: 0;
}

.response-history li {
    padding: 10px 0;
    border-bottom: 1px solid #e2e2e2;
}
