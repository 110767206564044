.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff; /* White background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0; /* Ensure there's no margin */
  z-index: 1000; /* Ensure header is above other content */
}

.header-fixed {
  position: fixed;
  top: 64px; /* Adjust based on the NavBar height */
  width: 100%;
  z-index: 1100; /* Ensure header is above other content */
}

.logo {
  display: flex;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  font-size: 1.5em;
  color: #3c577B;
}

.logo img {
  width: 130px; /* Adjust the size of the icon as needed */
  height: 100px; /* Adjust the size of the icon as needed */
  margin-right: 10px; /* Adjust the space between the icon and the text */
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #3c577B;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1em;
}

nav ul li a:hover {
  color: #007BFF;
}
