/* SetNotification.css */
.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light grey background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333; /* Darker color for headings */
}

.form-container label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #555; /* Slightly lighter than the heading */
}

.form-container input[type="text"],
.form-container input[type="date"],
.form-container input[type="time"],
.form-container textarea,
.form-container select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    background-color: #fff;
    color: #333;
}

.form-container input[type="radio"] {
    margin-right: 5px;
}

.form-container button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-container button[type="submit"] {
    background-color: #0056b3; /* Primary color */
    color: #fff;
    border: none;
}

.form-container button[type="submit"]:hover {
    background-color: #003d80; /* Darker shade for hover */
}

.form-container button[type="button"] {
    background-color: #ccc; /* Neutral color for secondary actions */
    color: #333;
    border: none;
    margin-left: 10px;
}

.form-container button[type="button"]:hover {
    background-color: #aaa; /* Darker shade for hover */
}

/* Confirmation Popup */
.confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%;
    max-width: 500px;
}

.popup-content {
    text-align: center;
}

.confirmation-popup h4 {
    margin-bottom: 20px;
    font-size: 20px;
}

.confirmation-popup p {
    margin: 10px 0;
    font-size: 16px;
}

.confirmation-popup ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.confirmation-popup li {
    margin: 5px 0;
    font-size: 16px;
}

.confirmation-popup button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirmation-popup button[type="button"] {
    background-color: #ccc;
    color: #333;
    border: none;
    margin-left: 10px;
}

.confirmation-popup button[type="button"]:hover {
    background-color: #aaa;
}
